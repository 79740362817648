/**
 * Get and structure meta data for routes
 */

// @REF: https://remix.run/docs/en/main/route/meta
// @NOTE: This may need revisiting if we have nested pages

// Type declarations
import type { MetaMatches } from '@remix-run/react/dist/routeModules'
import type { SanityCustomImage, RootDataProps } from '~/types'

/**
 * Gets a share image field and returns the relevant meta properties
 *
 * @param image
 * @returns
 */
interface ShareMetaProps {
  image: SanityCustomImage
}

export const getMetaShareImage = ({ image }: ShareMetaProps) => {
  const meta = []

  const imageUrl = image?.asset?.url ? image.asset.url : null

  if (imageUrl) {
    meta.push({
      property: 'og:image',
      content: imageUrl,
    })
    meta.push({
      property: 'twitter:image',
      content: imageUrl,
    })
    meta.push({
      property: 'twitter:card',
      content: 'summary_large_image',
    })
  }

  return meta
}

/**
 * Returns altered parent meta data for site pages
 *
 * @param {*} url
 * @returns
 */
interface MetaProps {
  matches: MetaMatches
  data: any
}

export const getMeta = ({ matches, data }: MetaProps) => {
  // Root CMS data
  const rootMatch = matches.find(e => e.id == 'root')
  const rootData = rootMatch?.data as RootDataProps
  const settings = rootData?.site?.settings
  const rootNoIndex = settings?.noIndex ?? false
  const rootUrl = rootData?.env?.SITE_URL ?? 'http://localhost:5173'

  // Direct parent data
  // const parentMeta = matches.flatMap((match: MetaMatch) => match.meta ?? [])

  // Current page data
  const pageData = data?.seo

  // Product only related meta titles
  const productMetaAttribute = data?.product?.stringAttributes
    ? data?.product?.stringAttributes?.find(
        (e: { name: string; value: string }) => e.name == 'metaTitle'
      )
    : null

  const productMetaTitle = productMetaAttribute?.value
    ? productMetaAttribute.value
    : data?.product?.name
      ? data.product.name
      : ''

  // See if we have a page meta title set, if not fallback to title
  const metaTitle = pageData?.metaTitle
    ? pageData?.metaTitle
    : data?.path === '/p'
      ? 'Search - ' + data?.query
      : productMetaTitle
        ? productMetaTitle
        : data?.title
          ? data.title
          : ''

  // Combine elements to make the URL
  const metaUrl = data?.path == '/' ? rootUrl : rootUrl + data.path

  // Meta data for page
  let meta = [
    { title: metaTitle },
    { name: 'description', content: pageData?.metaDescription ?? '' },
    {
      name: 'robots',
      content: rootNoIndex
        ? 'noindex'
        : pageData?.metaIndex
          ? pageData.metaIndex
          : 'all',
    },
    { tagName: 'link', rel: 'canonical', href: metaUrl },
    { property: 'og:url', content: metaUrl },
  ]

  // Additional share image
  if (settings?.shareImage || pageData?.shareImage) {
    const shareMeta = getMetaShareImage({
      image: pageData?.shareImage ? pageData.shareImage : settings.shareImage,
    })
    meta = meta.concat(shareMeta)
  }

  return meta
}
